import React from "react";
import style from "./ServiceFinancial.module.scss";
import cx from "classnames";

import GridCard from "./../../elements/Grid/GridCard";
import GridSix from "./../../elements/Grid/GridSix";

const ServiceFinancial = ({ iconSize }) => (
  <section>
    <div className={"container"}>
      <div className={cx("row", style.background)}>
        <div className='col-12'>
          <GridSix>
            <GridCard iconSize={iconSize} title={"Prestiti / Mutui"}>
              Ricevi profili di persone interessate ad una CQ, ad un prestito personale o ad un mutuo, filtrati in modo accurato.
            </GridCard>
            <GridCard iconSize={iconSize} title={"Conti on-line"}>
              Veicoliamo le tue campagne sul nostro network premium, attivando i canali più performanti.
            </GridCard>
            <GridCard iconSize={iconSize} title={"Telco / Utilities"}>
              Generiamo lead ad alta conversione, tramite asset proprietari creati in base alle tue esigenze.
            </GridCard>
            <GridCard iconSize={iconSize} title={"Startup / PMI"} green>
              Aiutiamo la tua startup / PMI a crescere attraverso le nostre campagne a performance.
            </GridCard>
            <GridCard iconSize={iconSize} title={"E-commerce"}>
              Aumentiamo le tue vendite, coinvolgendo online persone interessate al tuo prodotto.
            </GridCard>
            <GridCard iconSize={iconSize} title={"Noleggio a lungo termine"}>
              Attraverso i nostri form generiamo lead qualificati, principalmente sul verticale B2C.
            </GridCard>

          </GridSix>
        </div>
      </div>
    </div>
  </section>
);

export default ServiceFinancial;
