import React from "react";
import style from "./FullImageBanner.module.scss";

const FullImageBanner = ({ image, ctaLine1, ctaLine2 }) => {
  let backgroundImage = image || "/background/full-image.png"
  console.log(image)
  return (
    <section className={style.container} style={{ backgroundImage: `url('${backgroundImage}')`, }}>
      <div className={style.wrapper}>
        <div className={style.content}>
          <div className={style.line}>{ctaLine1 || "Un metodo collaudato."}</div>
          <div className={style.line}>{ctaLine2 || "Un team affiatato."}</div>
        </div>
      </div>
    </section>
  )
};

export default FullImageBanner;
