import React from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import ServiceFinancial from "../components/components/ServiceFinancial/ServiceFinancial";
import Space from "../components/elements/Space/Space";
import ContactForm from "../components/components/ContactForm/ContactForm";
import FullImageBanner from "../components/components/FullImageBanner/FullImageBanner";
import Text from "../components/atoms/Text/Text";
import Herobanner from "../components/elements/Herobanner/Herobanner";
import { scrollToContact } from "../utility/function";
import Clients from "../components/components/Clients/Clients";
import FormSlider, { FormSlide } from "../components/components/FormSlider/FormSlider";

const Finanziario = () => (
  <Page title='Cosa Facciamo' description='Ti aiutiamo a raggiungerli attraverso campagne a performance, in modalità Pay Per Lead o Pay Per Sale.' image='/favicon.png'>
    <Layout navigationCallback={() => scrollToContact()}>
      <Herobanner backgroundUrl={"/herobanner/finanziario.svg"}>
        <h1 className='mb-3'>I tuoi obiettivi sono anche i nostri</h1>
        <Text>Ti aiutiamo a raggiungerli attraverso campagne a performance, in modalità Pay Per Lead.</Text>
        <Button customClass='mt-6' onClick={() => scrollToContact()}>
          Parliamone
        </Button>
      </Herobanner>

      <Space mobile={10} table={15} desktop={20} />
      {/* 
      <Numbers icon='graph'>
        <Number number={"16K"} text={"Clienti totali"} />
        <Number number={"150000+"} text={"Leads generati"} />
        <Number number={"460+"} text={"Caffé presi"} />
      </Numbers>

      <Space mobile={10} table={4} desktop={10} />

      <ContactLineCTA callback={() => scrollToContact()} />

      <Space mobile={10} table={4} desktop={20} /> */}

      <ServiceFinancial iconSize='small'></ServiceFinancial>

      <Space mobile={20} table={20} desktop={20} />

      <FormSlider>
        <FormSlide label='Ricerca' image={"/slide/step_1.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              L’utente effettua una ricerca sul web
              <br />
              specifica per il suo bisogno.
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Landing page' image={"/slide/step_2.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>“Atterra” su un sito web a noi affiliato.</strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Form' image={"/slide/step_3.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>Compila il nostro form multi-step.</strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Verifica automatica' image={"/slide/step_4.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              Validazione automatica
              <br />
              di una parte dei dati.
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Verifica umana' image={"/slide/step_5.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              Verifica telefonica
              <br />
              da parte di un nostro operatore dei dati.
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Trasmissione lead' image={"/slide/step_6.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              Il lead viene trasmesso
              <br />
              via email o sul vostro CRM.
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
      </FormSlider>


      <FormSlider title='Campagne dirette'>
        <FormSlide label='Ricerca' image={"/slide/step_1.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              L’utente effettua una ricerca sul web
              <br />
              specifica per il suo bisogno.
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Landing page cliente' image={"/slide/step_2.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>Viene veicolato, attraverso il nostro network, alla tua landing page/sito web.</strong>
          </div>
          <Text></Text>
        </FormSlide>
        <FormSlide label='Funnel' image={"/slide/step_3_2.png"}>
          <div className='mt-3 text-center mb-4 px-2'>
            <strong>
              Entra nel tuo funnel specifico <br></br> (lead generation, acquisto online etc.).
            </strong>
          </div>
          <Text></Text>
        </FormSlide>
      </FormSlider>

      <Space mobile={20} table={20} desktop={20} />

      <FullImageBanner image='/background/full-image-affiliate.jpg' ctaLine1='La soddisfazione del cliente' ctaLine2='è ciò che ci muove ogni giorno.' />

      <Space mobile={20} table={20} desktop={20} />
      <Clients />

      <Space mobile={20} table={20} desktop={20} />

      <ContactForm text_line1='Richiedi ora informazioni' />
    </Layout>
  </Page>
);

export default Finanziario;
